import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useIntersection } from "react-use";

import { ValuationAddressAutocomplete } from "@/components/autocomplete/implementations/valuation";
import { GRID_PADDING, GUTTER } from "@/components/grid";
import { Typography } from "@/components/typography/typography";
import { PAGE_LIMIT } from "@/constants/layout";
import { useFormIntersectionContext } from "@/hooks/intersection";
import { TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";

// Use hasFeaturedImageMobile to fetch featuredImage only if there is no featuredImageMobile
export const Background = styled.div<
	PropsWithTheme & {
		backgroundImage?: string;
		hasFeaturedImageMobile?: boolean;
		fixedHeight?: string;
		wave?: boolean;
	}
>`
	display: flex;
	position: relative;
	width: 100%;
	${({
		theme: { palette, mq },
		backgroundImage,
		hasFeaturedImageMobile,
		wave,
		fixedHeight,
	}) => css`
		margin-bottom: var(--spacing-m);
		background-color: ${backgroundImage ? palette.freeze[1000] : "transparent"};
		color: ${palette.freeze[0]};
		background-image: ${backgroundImage && !hasFeaturedImageMobile
			? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundImage}?fm=webp&q=35&w=1200&h=800&f=faces&fit=thumb)`
			: `none`};
		background-size: ${backgroundImage ? "100% 100%, cover" : "auto 100%"};
		background-position: ${backgroundImage ? "50% 50%" : "100% 100%"};
		background-repeat: no-repeat;
		@media ${mq.m} {
			background-image: ${backgroundImage
				? `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${backgroundImage}?fm=webp&q=35&w=1600&h=1000&f=faces&fit=thumb)`
				: "none"};
		}
		@media ${mq.l} {
			background-color: ${palette.freeze[1000]};
			height: ${fixedHeight ? `calc(${fixedHeight} - var(--header-height))` : "auto"};
			background-image: ${wave ? "url(/assets/images/wave_visual.png)" : "none"};
			margin-bottom: var(--spacing-xxl);
		}
	`};
`;

export const BackgroundOverlay = styled.div`
	display: flex;
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	background-color: rgba(0, 0, 0, 0.3);
`;

export const VisualContent = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	flex: 1;
	flex-direction: column;
	min-height: 510px;
`;

export const VisualHeadline = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	z-index: 1;
	flex-direction: column;
	align-self: flex-end;
	padding: var(--spacing-xxl) 0 var(--spacing-l);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			padding: 0 0 var(--spacing-xxl);
		}
	`};
`;

export const ValuationArea = styled.div<PropsWithTheme>`
	margin-bottom: calc(var(--spacing-xxl) * -1);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			margin-bottom: calc(var(--spacing-l) * -1);
		}
	`};
`;

export const VisualImg = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	height: 684px;
	${({ theme: { mq, breakpoints } }) => css`
		/* Extend the image to the viewport edge */
		width: calc(
			100% + (var(${GRID_PADDING}) + var(${GUTTER})) * 1px + (100vw - ${breakpoints.xl}px) / 2
		);
		/* Limit image width to the image-boundary 
		 * Image will not extend on viewports beyond "imageBoundary"
		 */
		max-width: calc(
			100% + ${(PAGE_LIMIT - breakpoints.xl) / 2}px + (var(${GRID_PADDING}) + var(${GUTTER})) *
				1px
		);
		@media ${mq.l} {
			margin-bottom: calc(var(--spacing-xxxl) * -1);
		}
	`};
`;

export const Valuation: React.FC<{ title?: string }> = ({ title }) => {
	/**
	 * Snackbar Intersection Logic
	 */
	const ref = React.useRef<HTMLDivElement>();
	const intersection = useIntersection(ref, {
		root: null,
		rootMargin: "0px",
		threshold: 1,
	});
	const intersecting = React.useMemo(() => intersection?.isIntersecting, [intersection]);
	const { setIntersectingElement } = useFormIntersectionContext();
	React.useEffect(() => {
		setIntersectingElement(intersecting);
	}, [setIntersectingElement, intersecting]);

	return (
		<>
			{title && (
				<Typography component="h4" variant={TypographyVariant.headlineSansXXS}>
					{title}
				</Typography>
			)}
			<StyledFieldWrapper ref={ref}>
				<ValuationAddressAutocomplete />
			</StyledFieldWrapper>
		</>
	);
};

export const WaveImage = styled.div<PropsWithTheme>`
	display: none;
	${({ theme: { mq } }) => css`
		@media ${mq.m} {
			display: block;
			position: absolute;
			right: calc(var(${GUTTER}) * -2px);
			bottom: 0;
			width: 460px;
			height: 240px;
		}
	`};
`;

export const StyledFieldWrapper = styled.div<PropsWithTheme<HTMLDivElement>>`
	padding: 20px;
	position: relative;
	z-index: 1;
	${({ theme: { palette, tokens } }) => css`
		border-radius: ${tokens.borderRadius.xl};
		box-shadow: 0 30px 27px rgba(0, 0, 0, 0.09);
		background-color: ${palette.freeze[0]};
	`};
`;
