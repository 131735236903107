import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useTranslation } from "next-i18next";

import Link from "next/link";
import { useRouter } from "next/router";

import { Box, GRID_PADDING, GUTTER } from "@/components/grid";
import { ProgressBar } from "@/components/progress-bar";
import { Typography } from "@/components/typography/typography";
import { Button } from "@/design-system/atoms/button/";
import type { PropsWithTheme } from "@/theme";

import ArrowLeft from "../../../public/assets/svg-icons/i-arrow-left.svg";

export const ValuationWrapper = styled.div`
	min-height: calc(100vh - var(--header-height-ref));
	display: flex;
	flex: 1;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			min-height: initial;
		},
	`};
`;

const StyledValuation = styled.div<PropsWithTheme>`
	position: relative;
	flex: 1;
	padding-top: var(--spacing-xs);
	height: 93%;
	${({ theme: { palette, mq, tokens } }) => css`
		color: ${palette.freeze[1000]};
		background: ${palette.freeze[0]};
		@media ${mq.l} {
			border-radius: ${tokens.borderRadius["3xl"]};
			box-shadow: ${tokens.elevation.bottom["MD"]};
			height: 573px;
			margin-bottom: calc(var(--spacing-xxxl) * -2);
			overflow: hidden;
		}
	`};
`;

const StyledBox = styled(Box)<PropsWithTheme>`
	height: 100%;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			height: initial;
			padding: 40px;
		}
	`};
`;

const StyledCancelLink = styled.a<PropsWithTheme<HTMLAnchorElement>>`
	display: flex;
	align-items: center;
	border: 0;
	background: none;
	color: currentColor;
	font-family: inherit;
	font-size: inherit;
	text-decoration: none;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[300]};
	`};
`;

export const StyledArrowBackIcon = styled(ArrowLeft)`
	margin-right: var(--spacing-xxxs);
	width: 24px;
	height: 24px;
`;

export const StyledBack = styled.div<PropsWithTheme>`
	position: absolute;
	top: var(--spacing-s);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			left: var(--spacing-m);
			bottom: var(--spacing-m);
			top: auto;
		}
	`};
`;

export const StyledNext = styled.div<PropsWithTheme & { sticky?: boolean }>`
	display: flex;
	bottom: 0;
	align-items: center;
	justify-content: center;
	padding: var(--spacing-xs);
	box-shadow: 0 -30px 27px 0 rgba(0, 0, 0, 0.09);
	${({ theme: { mq, palette }, sticky }) => css`
		position: ${sticky ? "fixed" : "absolute"};
		left: ${sticky ? 0 : `calc((var(${GRID_PADDING}) + var(${GUTTER})) * -1px)`};
		right: ${sticky ? 0 : `calc((var(${GRID_PADDING}) + var(${GUTTER})) * -1px)`};
		background: ${palette.freeze[0]};
		@media ${mq.l} {
			bottom: var(--spacing-m);
			box-shadow: none;
			display: block;
			height: auto;
			left: auto;
			padding: 0;
			position: absolute;
			right: var(--spacing-m); /* @todo needs better logic when refactoring */
		}
	`};
`;

export const StyledNextButton = styled(Button)`
	flex: 1;
`;

export const StyledBackButton = styled.button`
	display: flex;
	align-items: center;
	height: 46px;
	border: 0;
	background: none;
`;

export const StyledBackButtonSmall = styled.button<PropsWithTheme<HTMLButtonElement>>`
	padding: 0;
	border: 0;
	background: none;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[1000]};
	`};
`;

export const StyledFieldGrid = styled.div<PropsWithTheme>`
	${({ theme: { mq } }) => css`
		display: grid;
		grid-gap: var(--spacing-xxs);
		@media ${mq.l} {
			grid-gap: var(--spacing-xs);
			grid-template-columns: repeat(2, 1fr);
		}
	`};
`;

export const StyledProgressBar = styled.div<PropsWithTheme>`
	position: absolute;
	top: 0;
	width: 100vw;
	${({ theme: { mq } }) => css`
		left: calc((var(${GRID_PADDING}) + var(${GUTTER})) * -1px);
		@media ${mq.l} {
			left: 0;
			width: 100%;
		}
	`};
`;

export const StyledGreenText = styled.span<PropsWithTheme>`
	${({ theme: { palette } }) => css`
		color: ${palette.green[500]};
	`};
`;

export const StyledSuccessTitle = styled(Typography)<PropsWithTheme>`
	${({ theme: { palette } }) => css`
		color: ${palette.green[500]};
	`};
`;

export const StyledActionButton = styled.button<PropsWithTheme<HTMLButtonElement>>`
	padding-left: 0;
	border: 0;
	background: none;
	text-align: left;
	${({ theme: { palette } }) => css`
		color: ${palette.blue[500]};
	`};
`;

export const ProgressBarWrapper: React.FC<{ step: number; numSteps: number }> = ({
	step,
	numSteps,
}) => {
	const { t } = useTranslation("valuation");

	const [current, setCurrent] = React.useState<number>(step);
	React.useEffect(() => {
		setCurrent(state => state + 1);
	}, []);

	return (
		<StyledProgressBar>
			<ProgressBar
				current={current}
				segments={numSteps}
				label={t("common:progress-status", { current, numSteps })}
			/>
		</StyledProgressBar>
	);
};

export const CancelButton: React.FC<{ href: string; as: string }> = ({ href, as }) => {
	const { t } = useTranslation("valuation");
	const { locale } = useRouter();

	return (
		<Link passHref href={href} as={as} locale={locale}>
			<StyledCancelLink data-test-id="valuation-steps:cancel">
				<StyledArrowBackIcon /> {t("cancel")}
			</StyledCancelLink>
		</Link>
	);
};

export const ValuationSteps: React.FC = ({ children }) => {
	return (
		<StyledValuation>
			<StyledBox>{children}</StyledBox>
		</StyledValuation>
	);
};

export const StyledForm = styled.form<PropsWithTheme>`
	min-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
