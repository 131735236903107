import React from "react";

import { ClassNames, ThemeProvider } from "@emotion/react";

import { useRouter } from "next/router";

import { EnvironmentLabel } from "@/components/environment-label";
import { GridOverlay } from "@/components/grid";
import { SEOTags } from "@/components/head/seo-tags";
import { StyledMain } from "@/components/layout/components";
import type { LayoutProps } from "@/components/layout/types";
import { PAGE_LIMIT } from "@/constants/layout";
import { FeatureFlagProvider } from "@/context/feature-flags";
import {
	FooterIntersectionProvider,
	FormIntersectionProvider,
	WidgetIntersectionProvider,
} from "@/context/intersection";
import { theme } from "@/theme";
import type {
	BusinessInfoCollection,
	NavigationCollection,
	TextCollection,
} from "@/types/contentful-api";

import { EvernestFooter } from "./footer";
import { EvernestHeader } from "./main/header";
import { Page } from "./page";
import { EvernestSidebar } from "./sidebar";
import { GlobalStyles } from "./styles";

const Layout: React.FC<
	LayoutProps<{
		enabledFeatures?: string[];
		textCollection: TextCollection;
		headerNavigation: NavigationCollection;
		footerNavigation: NavigationCollection;
		businessInfoCollection: BusinessInfoCollection;
	}>
> = ({ data, children, meta, url, translucentHeader }) => {
	const { events: routerEvents } = useRouter();

	const [open, setOpen] = React.useState(false);
	const toggleOpen = React.useCallback(() => {
		setOpen(state => !state);
	}, [setOpen]);

	React.useEffect(() => {
		const handleRouteChangeStart = () => {
			setOpen(false);
		};

		routerEvents.on("routeChangeStart", handleRouteChangeStart);

		return () => {
			routerEvents.off("routeChangeStart", handleRouteChangeStart);
		};
	}, []);

	const [isIOSSafari, setIsIOSSafari] = React.useState<boolean>(false);
	React.useEffect(() => {
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (isSafari) {
			const string = "iPhone OS ";
			const startIndex = navigator.userAgent.indexOf(string) + string.length;
			const endIndex = startIndex + 2;
			const iOSVersion = Number(navigator.userAgent.slice(startIndex, endIndex));

			setIsIOSSafari(iOSVersion >= 15);
		}
	}, []);

	return (
		<FeatureFlagProvider value={data?.enabledFeatures}>
			<SEOTags meta={meta} url={url} />
			<ThemeProvider theme={theme}>
				<GlobalStyles />
				<FooterIntersectionProvider>
					<ClassNames>
						{({ css, theme }) => (
							<Page
								className={
									translucentHeader &&
									css`
										/* stylelint-disable length-zero-no-unit */
										--header-height-ref: 0px;
										/* stylelint-enable length-zero-no-unit */
										@media ${theme.mq.l} {
											--header-height-ref: var(--header-height);
										}
									`
								}
							>
								{process.env.NEXT_PUBLIC_CLUSTER_ENV === "dev" && (
									<GridOverlay
										toggle
										className={css`
											&::before {
												content: "";
												position: absolute;
												top: 0;
												right: calc((1440px - 50%) / -2);
												bottom: 0;
												left: calc((1440px - 50%) / -2);
												max-width: ${PAGE_LIMIT}px;
												margin: 0 auto;
												box-shadow: 0 0 0 150vw blue;
												pointer-events: none;
											}
										`}
									/>
								)}
								<EvernestHeader
									open={open}
									toggleOpen={toggleOpen}
									translucentHeader={translucentHeader}
									data={data}
								/>
								<EvernestSidebar open={open} toggleOpen={toggleOpen} data={data} />
								<WidgetIntersectionProvider>
									<FormIntersectionProvider>
										<StyledMain isIOSSafari={isIOSSafari}>
											{children}
											<EnvironmentLabel />
										</StyledMain>
									</FormIntersectionProvider>
								</WidgetIntersectionProvider>
								<EvernestFooter data={data} />
							</Page>
						)}
					</ClassNames>
				</FooterIntersectionProvider>
			</ThemeProvider>
		</FeatureFlagProvider>
	);
};

export default Layout;
