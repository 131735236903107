import React, { isValidElement, useEffect, useRef } from "react";

import { useTranslation } from "next-i18next";

import { Typography } from "@/components/typography/typography";
import { TypographyVariant } from "@/theme";

import CrossIcon from "../../../public/assets/svg-icons/i-cross-lg.svg";

import { StyledCloseButton, StyledContent, StyledDialog } from "./styles";
import type { DialogProps } from "./types";

const DIALOG_TITLE_ID = "dialog-title";

const DialogTitle = ({ title }: { title: string | React.ReactNode }) => {
	if (isValidElement(title)) {
		return <div id={DIALOG_TITLE_ID}>{title}</div>;
	}

	return (
		<Typography id={DIALOG_TITLE_ID} variant={TypographyVariant.headlineSerifSM} component="p">
			{title}
		</Typography>
	);
};

export const Dialog = ({
	ariaLabel,
	ariaLabelledBy,
	children,
	dark,
	size = "sm",
	removePadding,
	open,
	title,
	onClose,
}: DialogProps) => {
	const ref = useRef<HTMLDialogElement>(null);
	const { t } = useTranslation("common");

	const handleClose = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.stopPropagation();
		if (ref.current) {
			ref.current.close();
		}
	};

	const handleBackdropClick = (event: React.MouseEvent<HTMLDialogElement, MouseEvent>) => {
		if (event.target instanceof HTMLDialogElement) {
			event.target.close();
		}
	};

	const handleContentClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		// This prevents the dialog from closing when clicking "inside" the dialog
		// Pairs with handleBackdropClick
		event.stopPropagation();
	};

	useEffect(() => {
		const dialog = ref.current;

		if (!dialog) {
			return;
		}

		if (open) {
			dialog.showModal();

			// We fire our own close event with the native close event when the dialog is closed
			// So that we can handle any external requirements like state changes
			dialog.addEventListener("close", onClose);
		}

		return () => {
			if (dialog) {
				dialog.removeEventListener("close", onClose);
			}
		};
	}, [onClose, open]);

	return (
		<StyledDialog
			aria-label={ariaLabel}
			aria-labelledby={ariaLabel ? undefined : ariaLabelledBy ?? DIALOG_TITLE_ID}
			aria-modal="true"
			dark={dark}
			ref={ref}
			size={size}
			onClick={handleBackdropClick}
		>
			<StyledContent onClick={handleContentClick} removePadding={removePadding}>
				{title && <DialogTitle title={title} />}
				{children}
			</StyledContent>
			<StyledCloseButton
				aria-label={t("common:close")}
				dark={dark}
				data-test-id="dialog-close-button"
				onClick={handleClose}
			>
				<CrossIcon aria-hidden="true" height="24" width="24" />
			</StyledCloseButton>
		</StyledDialog>
	);
};
