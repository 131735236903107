import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { RowProps } from "@/components/grid";
import { PADDING, Row } from "@/components/grid";
import { LoadingSpinner } from "@/components/loading-spinner";
import { Typography } from "@/components/typography/typography";
import { Button } from "@/design-system/atoms/button";
import { FONT_WEIGHT, TYPOGRAPHY } from "@/theme";
import type { PropsWithTheme } from "@/theme";

import CheckIcon from "../../../public/assets/svg-icons/i-check-lg.svg";

export const FormBox = styled.div<PropsWithTheme>`
	display: block;
	position: relative;
	z-index: 9;
	width: 100%;
	margin-top: 0;
	padding: var(--spacing-xs);
	${({ theme: { mq, palette, tokens } }) => css`
		background: ${palette.freeze[0]};
		box-shadow: ${tokens.elevation.bottom.MD};
		border-radius: ${tokens.borderRadius["2xl"]};
		color: ${palette.freeze[1000]};
		@media ${mq.l} {
			padding: var(--spacing-s);
			margin-top: var(--spacing-xl);
		}
	`};
`;

export const ContactFormBox = styled(FormBox)<PropsWithTheme>`
	margin-top: -50px;
	padding: var(--spacing-xs);
	overflow: hidden;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			margin-top: 0;
			padding: var(--spacing-s);
		}
	`};
`;

export const FlexButton = styled(Button)`
	width: 100%;
	text-align: center;
`;

export const StyledCircularProgress = styled(LoadingSpinner)`
	width: 22px;
	height: 22px;
	margin-right: var(--spacing-xxxs);
`;

export const StyledCheckIcon = styled(CheckIcon)<PropsWithTheme>`
	margin-right: var(--spacing-xs);
	width: 24px;
	height: 24px;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[500]};
	`};
`;

export const StyledFeatures = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

export const StyledFeature = styled.div<PropsWithTheme>`
	display: flex;
	align-items: center;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[1000]};
	`};
`;

export const Dropzone = styled.div`
	display: flex;
	justify-content: flex-start;
`;

export const Droppedzone = styled.div``;

export const Dropped = styled.div`
	display: flex;
	justify-content: flex-start;
	padding-bottom: var(--spacing-xxxs);
`;

export const DropzoneLabel = styled.div`
	display: flex;
	align-content: center;
	align-items: center;
	padding-right: var(--spacing-xxs);
	overflow: hidden;
`;

export const Ellipsis = styled(Typography)`
	overflow: hidden;
	text-overflow: ellipsis;
	vertical-align: center;
	white-space: nowrap;
`;

export const RoundButton = styled.button<PropsWithTheme<HTMLButtonElement>>`
	display: flex;
	align-content: center;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	${({ theme: { palette } }) => css`
		border: 1px solid ${palette.freeze[300]};
		background: ${palette.freeze[0]};
	`};
`;

export const StyledFileLabel = styled(Typography)<{ error?: boolean }>`
	${({ theme: { palette }, error }) => css`
		color: ${error ? palette.red[500] : "initial"};
	`};
`;

export const AgentWrapper = styled.div<PropsWithTheme>`
	position: relative;
	flex: 1;
	width: 100%;
	height: 380px;
	${({ theme: { mq, tokens } }) => css`
		@media ${mq.m} {
			height: 700px;
		}
		@media ${mq.l} {
			height: 590px;
			overflow: hidden;
			border-radius: ${tokens.borderRadius["3xl"]};
		}
	`};
`;

export const FormRow = styled(Row)<RowProps & PropsWithTheme>`
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: calc(100% + var(${PADDING}) * 2px);
			margin-left: 0;
			margin-right: 0;
		}
	`};
`;

export const StyledLabel = styled.label<
	PropsWithTheme<HTMLLabelElement> & { darkMode?: boolean; disabled?: boolean; error?: boolean }
>`
	display: flex;
	flex-direction: row;
	gap: 4px;
	font-weight: ${FONT_WEIGHT.medium};
	${TYPOGRAPHY.body.MD};
	${({ darkMode, disabled, error, theme }) => css`
		color: ${error
			? `${theme.palette.red[500]}`
			: `${
					disabled
						? `${darkMode ? theme.palette.freeze[400] : theme.palette.freeze[500]}`
						: `${darkMode ? theme.palette.freeze[0] : theme.palette.freeze[1000]}`
			  }`};

		& > svg {
			color: ${darkMode ? theme.palette.freeze[1000] : theme.palette.freeze[400]};
		}

		&:invalid {
			color: ${theme.palette.red[500]};
		}
	`};
`;

export const StyledAsterisk = styled.span<PropsWithTheme<HTMLSpanElement> & { error?: boolean }>`
	${({ error, theme }) => css`
		color: ${error ? theme.palette.red[500] : "inherit"};
	`};
`;

export const StyledErrorText = styled.span<PropsWithTheme<HTMLSpanElement>>`
	${({ theme }) => css`
		color: ${theme.palette.red[500]};
		${TYPOGRAPHY.body.SM};
	`};
`;

export const StyledHelperText = styled.span<PropsWithTheme<HTMLSpanElement>>`
	${({ theme }) => css`
		color: ${theme.palette.freeze[400]};
		${TYPOGRAPHY.body.SM};
	`};
`;
