import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { COLOR_PALETTE, TOKENS } from "@/theme";

import type { DialogProps } from "./types";

const SIZES = {
	sm: {
		maxWidth: "calc(min(600px, 100% - var(--spacing-xxs) * 2))",
	},
	md: {
		maxWidth: "calc(min(900px, 100% - var(--spacing-xxs) * 2))",
	},
	lg: {
		maxWidth: "calc(min(1200px, 100% - var(--spacing-xxs) * 2))",
	},
	fullScreen: {
		height: "100%",
		margin: 0,
		// Override native ":-internal-dialog-in-top-layer" styles else we get margins
		maxWidth: ["100vw", "100svw"],
		maxHeight: ["100vh", "100svh"],
	},
};

export const StyledDialog = styled.dialog<Pick<DialogProps, "dark" | "size">>`
	border: 0;
	padding: 0;
	position: fixed;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	z-index: 10000;
	${({ dark, size }) => css`
		background-color: ${dark ? COLOR_PALETTE.freeze[1000] : COLOR_PALETTE.freeze[0]};
		color: ${dark ? COLOR_PALETTE.freeze[0] : "initial"};
		border-radius: ${size === "fullScreen" ? 0 : TOKENS.borderRadius["2xl"]};
		${css(SIZES[size])}

		&::backdrop {
			background-color: ${size === "fullScreen" ? "transparent" : "rgba(0, 0, 0, 0.7)"};
		}
	`};

	&[open] {
		animation: fadeIn ${TOKENS.animation.duration.SM}
			${TOKENS.animation.ease.smooth.accelerated} forwards;
	}

	@keyframes fadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}
`;

export const StyledContent = styled.div<Pick<DialogProps, "removePadding">>`
	height: auto;
	${({ removePadding }) => css`
		padding: ${removePadding ? "0" : "var(--spacing-m)"};
	`};

	*:first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
`;

export const StyledCloseButton = styled.button<Pick<DialogProps, "dark">>`
	cursor: pointer;
	position: absolute;
	top: var(--spacing-xxs);
	right: var(--spacing-xxs);
	border: 0;
	background: none;
	padding: 0;
	line-height: 0;

	&:hover {
		color: ${COLOR_PALETTE.freeze[500]};
	}

	${({ dark }) => css`
		color: ${dark ? COLOR_PALETTE.freeze[0] : "initial"};
	`};
`;
