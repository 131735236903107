import React, { useCallback } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import type { Options as SplideOptions } from "@splidejs/splide";
import { useTranslation } from "next-i18next";

import Image from "next/image";

import { Dialog } from "@/components/dialog";
import { Controls, SlideshowButton } from "@/components/splide";
import { Icon } from "@/design-system/atoms/icons";
import type { SliderProps } from "@/types/dialog";

import { StyledThumb, StyledTrack } from "./components";

const splideOptions: SplideOptions = {
	type: "loop",
	height: "calc(100vh - 80px)",
	pagination: false,
	arrows: false,
};

const splideThumbnailsOptions: SplideOptions = {
	height: 70,
	fixedWidth: 100,
	width: "100vw",
	gap: 0,
	rewind: true,
	isNavigation: true,
	pagination: false,
	arrows: false,
};

export const LightboxGallery: React.FC<SliderProps> = ({
	open = false,
	initialSlide = 0,
	onClose,
	slides,
}) => {
	const { t } = useTranslation();
	const sliderRef = React.useRef<Splide>(null);
	const thumbsRef = React.useRef<Splide>(null);
	const [selected, setSelected] = React.useState<number>(initialSlide);

	const goToNext = useCallback(() => {
		sliderRef?.current.go(">");
	}, [sliderRef]);

	const goToPrevious = useCallback(() => {
		sliderRef?.current.go("<");
	}, [sliderRef]);

	React.useEffect(() => {
		if (sliderRef?.current && thumbsRef?.current) {
			// Triggers a refresh of the slider to calc correct slide positioning after opening dialog
			requestAnimationFrame(() => {
				sliderRef.current?.splide?.refresh();
			});

			sliderRef.current.splide.sync(thumbsRef.current.splide);
		}
	}, [sliderRef, thumbsRef]);

	return (
		<Dialog
			dark
			ariaLabel={t("slideshow:gallery")}
			removePadding
			open={open}
			size="fullScreen"
			onClose={onClose}
		>
			<Controls>
				<SlideshowButton small aria-label={t("prev-slide")} onClick={goToPrevious}>
					<Icon icon="arrowLeft" />
				</SlideshowButton>
				<SlideshowButton small aria-label={t("next-slide")} onClick={goToNext}>
					<Icon icon="arrowRight" />
				</SlideshowButton>
			</Controls>
			<div>
				<Splide
					ref={sliderRef}
					hasTrack={false}
					options={{
						...splideOptions,
						start: selected,
						i18n: {
							prev: t("slideshow:prev-property"),
							next: t("slideshow:next-property"),
							slidex: t("slideshow:go-to-property-x"),
						},
					}}
					onMove={(_, newSlide) => {
						setSelected(newSlide);
					}}
				>
					<StyledTrack>
						{slides.map(image => (
							<SplideSlide key={`${image.sys.id as string}-lb`}>
								<Image
									src={
										image?.__typename === "Picture"
											? `${image.file.url as string}?fm=jpg&q=80&w=1800&f=${
													(image.focus as string) ?? ""
											  }&fit=${(image.fit as string) ?? ""}`
											: `${image.url as string}?fm=jpg&q=80&w=1800`
									}
									alt={image.description as string}
									layout="fill"
									objectFit="contain"
									quality={90}
									sizes="100vw"
								/>
							</SplideSlide>
						))}
					</StyledTrack>
				</Splide>
				<Splide
					ref={thumbsRef}
					options={{
						...splideThumbnailsOptions,
						start: selected,
					}}
				>
					{slides.map((image, index) => (
						<StyledThumb
							key={`${image.sys.id as string}-lbthumb`}
							selected={index === selected}
						>
							<Image
								src={
									image?.__typename === "Picture"
										? `${image.file.url as string}?fm=jpg&q=50&w=200&h=120&f=${
												(image.focus as string) ?? ""
										  }&fit=${(image.fit as string) ?? ""}`
										: `${image.url as string}?fm=jpg&q=50&w=200&h=120`
								}
								alt={image.description}
								layout="fill"
								objectFit="cover"
								quality={50}
							/>
						</StyledThumb>
					))}
				</Splide>
			</div>
		</Dialog>
	);
};
