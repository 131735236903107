import React from "react";

import { useTranslation } from "next-i18next";
import { Controller, useForm } from "react-hook-form";

import { Button } from "@/components/buttons";
import { ButtonVariants } from "@/components/buttons/types";
import { CheckBox } from "@/components/checkbox";
import { StyledCircularProgress } from "@/components/forms/";
import { Transdown } from "@/components/i18n";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import {
	GridAreas,
	ButtonGridArea,
	ConsentGridArea,
	StyledForm,
	EmailGridArea,
} from "@/components/newsletter-signup/components";
import { TextField } from "@/components/text-fields";
import { Typography } from "@/components/typography/typography";
import { useNewsletterSignup } from "@/hooks/newsletter";
import { VALIDATION_PATTERNS } from "@/utils/validation";

import type { NewsletterSignupProps } from "./types";

export enum FormKeys {
	email = "newsletterEmail",
	consent = "newsletterConsent",
}

export const NewsletterSignup = ({ footer, textCollection }: NewsletterSignupProps) => {
	const { t } = useTranslation(["forms", "valuation"]);
	const postErrorMessage = textCollection.items.find(({ id }) => id === "postError").description;

	const { control, handleSubmit, errors } = useForm({
		reValidateMode: "onChange",
	});

	// Take care of duplicate keys in cases where the component is on the page twice
	const emailKey = footer ? `${FormKeys.email}Footer` : FormKeys.email;
	const checkboxKey = footer ? `${FormKeys.consent}Footer` : FormKeys.consent;

	const { error, loading, subscribe, success } = useNewsletterSignup();

	const handleFormSubmit = React.useCallback(
		({ [emailKey]: newsletterEmail }) => {
			void subscribe(newsletterEmail);
		},
		[emailKey, subscribe]
	);

	const WrappingComponent = footer ? "div" : GridAreas;

	return success ? (
		<Typography data-test-id={footer && `footer-newsletter:success`}>
			{t("forms:newsletter.success")}
		</Typography>
	) : error ? (
		<div>
			<Md source={postErrorMessage} />
		</div>
	) : (
		<StyledForm noValidate onSubmit={handleSubmit(handleFormSubmit)}>
			<WrappingComponent>
				<EmailGridArea>
					<Controller
						name={emailKey}
						control={control}
						defaultValue=""
						rules={{
							required: true,
							pattern: VALIDATION_PATTERNS.email,
						}}
						render={({ onChange, value }) => (
							<TextField
								required
								fullWidth
								darkMode={footer}
								data-test-id={footer && "footer-newsletter:email"}
								label={t("formal.email.label")}
								name={emailKey}
								error={Boolean(errors[emailKey])}
								errorText={t("formal.email.error")}
								helperText={!errors[emailKey] && " "}
								onChange={event => onChange(event.target.value)}
								value={value}
							/>
						)}
					/>
				</EmailGridArea>
				<ConsentGridArea>
					<Spacer spacing="xxs" />
					<Controller
						name={checkboxKey}
						control={control}
						defaultValue={false}
						rules={{
							required: true,
						}}
						render={({ onChange, value }) => (
							<CheckBox
								checked={value}
								data-test-id={footer && `footer-newsletter:consent`}
								error={errors[checkboxKey]}
								errorText={t("forms:formal.consent.error")}
								name={checkboxKey}
								label={<Transdown i18nKey="forms:newsletter.consent.label" />}
								labelSize="SM"
								onChange={event => {
									onChange(event.target.checked);
								}}
							/>
						)}
					/>
				</ConsentGridArea>
				<ButtonGridArea>
					<Button
						fullWidth
						data-test-id={footer && `footer-newsletter:submit`}
						disabled={loading}
						loading={loading}
						size="XL"
						type="submit"
						variant={footer ? ButtonVariants.secondary : ButtonVariants.primary}
					>
						{loading && <StyledCircularProgress />}
						{t("forms:send")}
					</Button>
				</ButtonGridArea>
			</WrappingComponent>
		</StyledForm>
	);
};
