import React, { useState } from "react";

import styled from "@emotion/styled";

import { Typography } from "@/components/typography/typography";
import type { PropsWithTheme } from "@/theme";

const CLUSTER_ENV = process.env.NEXT_PUBLIC_CLUSTER_ENV || "prod";

const ENV_GRADIENT = {
	dev: "linear-gradient(to bottom right, #434AEE, #7B31B5);",
	stag: "linear-gradient(to bottom right, #F04438, #D2843F);",
	preview: "linear-gradient(to bottom right, #FB49BE, #DC4141);",
	prod: "none",
};

const ENV_TEXT = {
	dev: "DEVELOPMENT",
	stag: "STAGING",
	preview: "PREVIEW",
	prod: "",
};

const StyledTypography = styled(Typography)<PropsWithTheme>`
	background: ${ENV_GRADIENT[CLUSTER_ENV]};
	border-radius: 5px;
	color: #fff;
	font-size: 14px;
	padding: var(--spacing-xxxs) var(--spacing-xxs);
	top: 15px;
	left: 15px;
	position: fixed;
	z-index: 100;
`;

export const EnvironmentLabel = () => {
	const [show, setShow] = useState(true);

	if (CLUSTER_ENV === "prod" || !show) {
		return null;
	}

	return (
		<StyledTypography
			tight
			onClick={() => {
				setShow(state => !state);
			}}
		>
			{ENV_TEXT[CLUSTER_ENV]}
		</StyledTypography>
	);
};
