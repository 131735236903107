// @todo This is a modified version of the existing ScrollableRow `@/components/boxes` (potential replacement)
import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useDebouncedCallback } from "use-debounce";

import type { ColumnType } from "@/components/grid";
import { Column } from "@/components/grid";
import { SlideshowButton } from "@/components/splide";
import { Icon } from "@/design-system/atoms/icons";
import { useViewport } from "@/design-system/organisms/slideshow-x/rewrites";
import {
	AbsoluteRow,
	NumbersWrapper,
	Pagers,
	ScrollableRow,
	SlideshowWrapper,
} from "@/design-system/organisms/slideshow-y/styled";
import type { PropsWithTheme } from "@/theme";

import PhotoCameraOutlined from "../../../../public/assets/svg-icons/i-image-stack.svg";

export const Numbers = styled.div<PropsWithTheme>`
	display: inline-grid;
	grid-gap: 4px;
	grid-template-columns: auto auto;
	grid-template-rows: 1em;
	align-items: center;
	align-self: flex-end;
	margin: 0 0 var(--spacing-xxs) var(--spacing-xxs);
	padding: var(--spacing-xxxs);
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.3);
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[0]};
	`};
`;

export const SlideshowY: React.FC<{
	parentColCountL: number;
	colCountL: number;
	children: ColumnType;
}> = ({ children, colCountL, parentColCountL }) => {
	const count = React.Children.count(children);
	const scrollRef = React.useRef<HTMLDivElement>(null);
	const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
	const { l } = useViewport();

	const goToNext = React.useCallback(() => {
		setCurrentSlideIndex(previousState => previousState + 1);
	}, []);

	const goToPrevious = React.useCallback(() => {
		setCurrentSlideIndex(previousState => previousState - 1);
	}, []);

	const handleScroll = React.useCallback(
		event_ => {
			const activeAfterScroll = Math.ceil(
				l
					? (event_.target.scrollLeft / event_.target.offsetWidth / colCountL) *
							parentColCountL
					: event_.target.scrollLeft / event_.target.offsetWidth
			);
			setCurrentSlideIndex(activeAfterScroll);
		},
		[l, colCountL, parentColCountL]
	);

	const handleScrollDebounced = useDebouncedCallback(handleScroll, 300);

	React.useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current.scrollTo({
				top: 0,
				left: Math.round(
					l
						? (scrollRef.current.offsetWidth / parentColCountL) *
								colCountL *
								currentSlideIndex
						: scrollRef.current.offsetWidth * currentSlideIndex
				),
				behavior: "smooth",
			});
		}
	}, [currentSlideIndex, scrollRef, l, colCountL, parentColCountL]);

	return (
		<SlideshowWrapper>
			<ScrollableRow ref={scrollRef} onScroll={handleScrollDebounced}>
				{children}
			</ScrollableRow>
			{count > 1 && (
				<AbsoluteRow>
					<Column>
						<NumbersWrapper>
							<Numbers>
								{currentSlideIndex + 1}/{count}
								<PhotoCameraOutlined width="24" height="24" />
							</Numbers>
						</NumbersWrapper>
					</Column>
				</AbsoluteRow>
			)}
			<Pagers>
				<SlideshowButton disabled={currentSlideIndex === 0} onClick={goToPrevious}>
					<Icon icon="chevronLeft" />
				</SlideshowButton>
				<SlideshowButton disabled={currentSlideIndex === count - 1} onClick={goToNext}>
					<Icon icon="chevronRight" />
				</SlideshowButton>
			</Pagers>
		</SlideshowWrapper>
	);
};
