import { TOKENS } from "@/theme/token";

export const TextFieldSizes = {
	XL: {
		height: "48px",
		padding: "12px 12px 11px 12px",
		borderRadius: TOKENS.borderRadius.lg,
	},
	LG: {
		height: "40px",
		padding: "8px 10px 7px 10px",
		borderRadius: TOKENS.borderRadius.md,
	},
};
