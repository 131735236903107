import React from "react";

import Image from "next/image";

import { BreakLines } from "@/components/break-lines";
import { Box, Column, Grid, Row } from "@/components/grid";
import { LightboxGallery } from "@/components/lightbox";
import { Md } from "@/components/markdown";
import { ColoredSlotWrapper } from "@/components/slot/slots/wrappers";
import { Typography } from "@/components/typography/typography";
import { SlideshowY } from "@/design-system/organisms/slideshow-y";
import {
	Caption,
	SlideInner,
	SlideSizer,
	SnapColumn,
} from "@/design-system/organisms/slideshow-y/styled";
import { TypographyVariant } from "@/theme";
import { BackgroundColor } from "@/theme";
import type { ImageSlideshow as ImageSlideshowSlotProps } from "@/types/contentful-api";
import type { AssetFit, AssetFocus } from "@/types/contentful-images";
import { getComputedAspectRatio } from "@/utils/aspect-ratio";

export const ImageSlideshowSlot = (props: ImageSlideshowSlotProps) => {
	const [lightboxOpen, setLightboxOpen] = React.useState<boolean>(false);
	const [lightboxInitialSlide, setLightboxInitialSlide] = React.useState<number>(0);
	const computedAspectRatio = getComputedAspectRatio(props?.aspectRatio);
	const slideshowItems = props.slotsCollection.items.filter(Boolean);

	return (
		<>
			<ColoredSlotWrapper
				primary={BackgroundColor[props.backgroundPrimary]}
				secondary={BackgroundColor[props.backgroundPrimary]}
			>
				<Grid>
					<Row>
						{props.headline && (
							<Column>
								<Typography
									component="h2"
									centered={props.headlineCentered}
									variant={TypographyVariant.headlineSerifLG}
								>
									<BreakLines text={props.headline} />
								</Typography>
							</Column>
						)}
						{props.description && (
							<Column>
								<Md source={props.description} />
							</Column>
						)}
						<Column>
							<SlideshowY
								colCountL={props.slotsCollection.total > 1 ? props.columns : 12}
								parentColCountL={12}
							>
								{slideshowItems.map((slide, index) => {
									return (
										<SnapColumn
											key={slide.sys.id}
											l={props.slotsCollection.total > 1 ? props.columns : 12}
										>
											<SlideSizer aspectRatio={computedAspectRatio}>
												<SlideInner
													withLightbox={props.withLightbox}
													onClick={
														props.withLightbox
															? () => {
																	setLightboxOpen(true);
																	setLightboxInitialSlide(index);
															  }
															: undefined
													}
												>
													<Box removeGutter flex>
														<Box addGutter>
															<Image
																src={`${
																	slide.file.url
																}?fm=jpg&q=100&w=1280&f=${
																	(slide.focus as AssetFocus) ??
																	""
																}&fit=${
																	(slide.fit as AssetFit) ?? ""
																}`}
																alt={slide.alt}
																layout="fill"
																objectFit="cover"
																quality={80}
																sizes="(max-width: 1199px) 95vw, 60vw"
															/>
														</Box>
													</Box>
												</SlideInner>
											</SlideSizer>
											<Caption>
												<Typography tight>{slide.caption}&nbsp;</Typography>
											</Caption>
										</SnapColumn>
									);
								})}
							</SlideshowY>
						</Column>
					</Row>
				</Grid>
			</ColoredSlotWrapper>

			{lightboxOpen && (
				<LightboxGallery
					open={lightboxOpen}
					initialSlide={lightboxInitialSlide}
					slides={slideshowItems}
					onClose={() => {
						setLightboxOpen(false);
					}}
				/>
			)}
		</>
	);
};
