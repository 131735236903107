import React from "react";

import CheckedIcon from "../../../public/assets/svg-icons/i-checkbox-checked.svg";
import UncheckedIcon from "../../../public/assets/svg-icons/i-checkbox-unchecked.svg";
import { StyledErrorText } from "../forms";

import { StyledCheckboxInput, StyledLabel, StyledText } from "./styled";
import type { CheckboxProps } from "./types";

/***
 * Checkbox component, based on a checkbox input
 * Design: https://www.figma.com/design/cYVmvu20RplQZs7ns7qxHM/Website-ComponentKit?node-id=3949-10136&t=9xh9NsZjFhx5cOyE-0
 */
export const CheckBox = ({
	checked,
	error,
	errorText,
	label,
	labelSize = "SM",
	name,
	onChange,
	...props
}: CheckboxProps) => (
	<>
		<StyledLabel labelSize={labelSize} checked={checked}>
			<StyledCheckboxInput
				checked={checked}
				name={name}
				onChange={event => {
					onChange(event);
				}}
				type="checkbox"
				{...props}
			/>
			{checked ? (
				<CheckedIcon width="16px" height="16px" />
			) : (
				<UncheckedIcon width="16px" height="16px" />
			)}
			<StyledText labelSize={labelSize}>{label}</StyledText>
		</StyledLabel>
		{error && errorText && (
			<StyledErrorText data-test-id={`${name}-checkbox-error`} role="alert">
				{errorText}
			</StyledErrorText>
		)}
	</>
);
