import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";

import { Typography } from "@/components/typography/typography";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";

import { ButtonVariants } from "../buttons/types";
import { StyledAsterisk, StyledErrorText, StyledLabel } from "../forms";

import { StyledChevron, StyledSelect, WrappingDiv, WrappingLabel } from "./styled";
import type { SelectProps } from "./types";

export const Select = ({
	disabled = false,
	required = false,
	error = false,
	fullWidth = false,
	errorText,
	label,
	name,
	onSelect,
	options,
	placeholderText,
	size = "XL",
	variant = ButtonVariants.tertiary,
	value,
}: SelectProps) => {
	const [text, setText] = useState<string>(placeholderText);
	const [selectedValue, setSelectedValue] = useState(value);

	const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const value = event.target.value;
		setSelectedValue(value);
		onSelect(value);

		const selectElement = event.target;
		const selectedText = selectElement.options[selectElement.selectedIndex].text;
		setText(selectedText);
	};

	// If a label is provided, we use it as an explicit label above the component
	// If no label, we use the "name" as the label
	const WrappingComponent = label ? WrappingDiv : WrappingLabel;

	useEffect(() => {
		setSelectedValue(value);
		const selectedOption = options.find(option => option.value === value);
		setText(selectedOption ? selectedOption.name : placeholderText);
	}, [value, options, placeholderText]);

	return (
		<div>
			{label && (
				<StyledLabel disabled={disabled} error={error} htmlFor={name}>
					{label}
					{required && <StyledAsterisk error={error}>*</StyledAsterisk>}
				</StyledLabel>
			)}
			<WrappingComponent error={error} fullWidth={fullWidth} size={size} variant={variant}>
				<StyledSelect
					aria-labelledby={label ? undefined : name}
					aria-invalid={error}
					name={name}
					onChange={handleChange}
					value={selectedValue}
				>
					<option selected disabled hidden value="">
						{placeholderText}
					</option>
					{options.map(({ name, value }) => (
						<option key={value} value={value}>
							{name}
						</option>
					))}
				</StyledSelect>
				<Typography
					tight
					id={name}
					variant={TypographyVariant.labelMD}
					weight={FONT_WEIGHT.medium}
				>
					{text}
				</Typography>
				<StyledChevron aria-hidden="true" height="20" width="20" />
			</WrappingComponent>
			{error && errorText && <StyledErrorText role="alert">{errorText}</StyledErrorText>}
		</div>
	);
};
