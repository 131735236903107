import React from "react";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import type { Variants } from "framer-motion";
import { useTranslation } from "next-i18next";

import Image from "next/image";

import { ActionButtonLink } from "@/components/action-button-link";
import { BreakLines } from "@/components/break-lines";
import { Column, COLUMN, Grid, Hidden, Row } from "@/components/grid";
import { AbsoluteRow, RelativeColumn } from "@/components/grid/extensions";
import { Spacer } from "@/components/layout/components";
import { options } from "@/components/rich-text";
import { ColoredSlotWrapper } from "@/components/slot/slots/wrappers";
import { Typography } from "@/components/typography/typography";
import { Icon } from "@/design-system/atoms/icons";
import {
	SlideshowX,
	SlideshowXMedia,
	SlideshowXSlide,
	StyledControl,
	StyledControls,
	StyledDot,
	StyledDots,
	StyledSlideshowDots,
	StyledSlideshowXSlideSection,
	StyledSlideshowXSlideWrapper,
} from "@/design-system/organisms/slideshow-x";
import { useViewport } from "@/design-system/organisms/slideshow-x/rewrites";
import type { PropsWithTheme } from "@/theme";
import { TypographyVariant } from "@/theme";
import { BackgroundColor } from "@/theme";
import type { PictureText, Slideshow as SlideshowSlotProps } from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";
import { getComputedAspectRatio } from "@/utils/aspect-ratio";

import PhotoCameraOutlined from "../../../../public/assets/svg-icons/i-image-stack.svg";

export const duration = 0.3;
export const delay = duration / 4;
export const ease = "easeInOut";
export const variants: Variants = {
	current: {
		x: 0,
		transition: { delay, duration, ease },
		visibility: "visible",
	},
	previous: {
		x: "200%",
		transition: { delay, duration, ease },
		transitionEnd: {
			visibility: "hidden",
		},
	},
	next: {
		x: "-200%",
		transition: { delay, duration, ease },
		transitionEnd: {
			visibility: "hidden",
		},
	},
};
export const media: Variants = {
	current: {
		x: 0,
		transition: { duration, ease },
	},
	previous: {
		x: "100%",
		transition: { duration, ease },
	},
	next: {
		x: "-100%",
		transition: { duration, ease },
	},
};

export const Numbers = styled.div<PropsWithTheme>`
	display: inline-grid;
	grid-gap: 4px;
	grid-template-columns: auto auto;
	grid-template-rows: 1em;
	align-items: center;
	align-self: center;
	margin: 0 0 0 var(--spacing-xxs);
	padding: var(--spacing-xxxs);
	border-radius: 5px;
	background: rgba(0, 0, 0, 0.3);
	user-select: none;
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[0]};
	`};
`;

export interface SlideshowSlideProps {
	flip?: boolean;
	dark?: boolean;
	slide: PictureText;
	current?: boolean;
	previous?: boolean;
	next?: boolean;
	aspectRatio: number;
	slideCount: number;
	lastSlide?: boolean;
	active: number;
	toPrevious(): void;
	toNext(): void;
}

const SlideshowSlide = ({
	flip,
	slide,
	current,
	previous,
	next,
	aspectRatio,
	slideCount,
	lastSlide,
	active,
	toPrevious,
	toNext,
	dark,
}: SlideshowSlideProps) => {
	const { t } = useTranslation("slideshow");
	const { m } = useViewport();
	const { mediaAssetImage, picture, text, navigationItem, headline } = slide;

	const richText = React.useMemo(() => {
		return documentToReactComponents(text?.json, options);
	}, [text]);

	// A PictureText content-type can have a Picture or a Media Asset
	const hasImage = Boolean(picture) || Boolean(mediaAssetImage);
	const imageOptions = {
		alt: picture?.alt ?? mediaAssetImage?.description,
		src: picture?.file?.url ?? mediaAssetImage?.url,
		fit: (picture?.fit as AssetFit) ?? AssetFit.fill,
		f: (picture?.focus as AssetFocus) ?? AssetFocus.faces,
	};

	return (
		<StyledSlideshowXSlideWrapper>
			<Row>
				<RelativeColumn m={COLUMN.TWO}>
					<StyledSlideshowXSlideSection shouldClip>
						<SlideshowXSlide
							variants={media}
							current={current}
							previous={previous}
							next={next}
						>
							<SlideshowXMedia aspectRatio={aspectRatio}>
								{hasImage && (
									<Image
										unoptimized
										loading="lazy"
										src={`${imageOptions.src}?q=75&w=740&h=740&fm=${AssetFormat.webp}&f=${imageOptions.f}&fit=${imageOptions.fit}`}
										alt={imageOptions.alt}
										layout="fill"
										objectFit="cover"
									/>
								)}
							</SlideshowXMedia>
						</SlideshowXSlide>
					</StyledSlideshowXSlideSection>
					{lastSlide && slideCount > 1 && (
						<StyledControls>
							<AbsoluteRow>
								<Column flex style={{ alignItems: "center" }}>
									<StyledControl
										type="button"
										disabled={active === 0}
										aria-label={t("prev-slide")}
										onClick={toPrevious}
									>
										<Icon icon="chevronLeft" />
									</StyledControl>
									<StyledControl
										type="button"
										disabled={active === slideCount - 1}
										aria-label={t("next-slide")}
										onClick={toNext}
									>
										<Icon icon="chevronRight" />
									</StyledControl>
									<Numbers>
										{active + 1}/{slideCount}
										<PhotoCameraOutlined width="20" height="20" />
									</Numbers>
								</Column>
							</AbsoluteRow>
						</StyledControls>
					)}
				</RelativeColumn>
				<RelativeColumn m={COLUMN.TWO} order={flip && m ? -1 : undefined}>
					<Hidden s>
						<Spacer spacing="xs" />
					</Hidden>
					<StyledSlideshowXSlideSection shouldClip>
						<SlideshowXSlide
							variants={variants}
							current={current}
							previous={previous}
							next={next}
						>
							<Row>
								<Column s={0} l={flip ? 0 : 1} />
								<Column l={5}>
									{headline && (
										<Typography
											component="h2"
											variant={TypographyVariant.headlineSerifLG}
										>
											{headline}
										</Typography>
									)}
									{richText}
									{navigationItem && (
										<>
											<ActionButtonLink
												navigationItem={navigationItem}
												dark={dark}
											/>
											<Hidden l>
												<Spacer spacing="xxxl" />
											</Hidden>
										</>
									)}
								</Column>
								<Column s={0} l={flip ? 1 : 0} />
							</Row>
						</SlideshowXSlide>
					</StyledSlideshowXSlideSection>
				</RelativeColumn>
			</Row>
		</StyledSlideshowXSlideWrapper>
	);
};

const Slideshow = (props: SlideshowSlotProps) => {
	const { flip, aspectRatio } = props;
	const [active, setActive] = React.useState(0);

	const { items: slides } = props.slotsCollection;
	const { length: slideCount } = slides;

	const next = React.useCallback(() => {
		setActive(s => Math.min(slideCount - 1, s + 1));
	}, [slideCount]);

	const previous = React.useCallback(() => {
		setActive(s => Math.max(0, s - 1));
	}, []);

	const handleScroll = React.useCallback(event => {
		const slider = event.currentTarget;
		const activeAfterScroll = Math.round(slider.scrollLeft / slider.offsetWidth);
		setActive(activeAfterScroll);
	}, []);
	const backgroundPrimary = props.backgroundPrimary ?? "white";
	const backgroundSecondary = props.backgroundSecondary ?? backgroundPrimary;
	const dark = backgroundPrimary === "black";
	const computedAspectRatio = getComputedAspectRatio(aspectRatio);

	return (
		<ColoredSlotWrapper
			primary={BackgroundColor[backgroundPrimary]}
			secondary={BackgroundColor[backgroundSecondary]}
		>
			<Grid>
				<Row>
					{props.headline && (
						<Column>
							<Typography
								bottom
								centered={props.headlineCentered}
								component="h2"
								variant={TypographyVariant.headlineSerifLG}
							>
								<BreakLines text={props.headline} />
							</Typography>
						</Column>
					)}
					<RelativeColumn>
						<StyledSlideshowDots aspectRatio={computedAspectRatio}>
							<StyledDots>
								{slides.map(({ sys: { id } }, i) => {
									return <StyledDot key={id} active={active === i} />;
								})}
							</StyledDots>
						</StyledSlideshowDots>
						<SlideshowX onScroll={handleScroll}>
							{slides.map((slide, i) => {
								return (
									<SlideshowSlide
										key={slide.sys.id}
										slide={slide}
										toPrevious={previous}
										toNext={next}
										dark={dark}
										flip={flip}
										slideCount={slideCount}
										active={active}
										current={active === i}
										lastSlide={i === slideCount - 1}
										previous={active < i}
										next={active > i}
										aspectRatio={computedAspectRatio}
									/>
								);
							})}
						</SlideshowX>
					</RelativeColumn>
				</Row>
			</Grid>
		</ColoredSlotWrapper>
	);
};

export const SlideshowSlot = (props: SlideshowSlotProps) => {
	return <Slideshow {...props} />;
};
