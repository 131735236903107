import React from "react";

import { useTranslation } from "next-i18next";
import type { SubmitHandler } from "react-hook-form";
import { Controller, useForm } from "react-hook-form";
import { useIntersection } from "react-use";

import Image from "next/image";
import { useRouter } from "next/router";

import { Button } from "@/components/buttons";
import { CheckBox } from "@/components/checkbox";
import { Alignment, COLUMN, Column, Grid, Hidden, Row } from "@/components/grid";
import { RelativeRow, RelativeStage } from "@/components/grid/extensions";
import { Transdown } from "@/components/i18n";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import { TextField } from "@/components/text-fields";
import { Typography } from "@/components/typography/typography";
import { endpoints } from "@/endpoints";
import { usePost } from "@/hooks/data";
import { useFormIntersectionContext } from "@/hooks/intersection";
import { useNewsletterSignup } from "@/hooks/newsletter";
import { useTrackingEvent } from "@/hooks/tag-manager";
import { getUTMParameters } from "@/services/google-analytics/utm";
import { TypographyVariant } from "@/theme";
import type { Person, Picture } from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";
import { imageOrFallback } from "@/utils/image";
import { VALIDATION_PATTERNS } from "@/utils/validation";

import { AgentWrapper, ContactFormBox, FormRow } from "../";
import { ContactFormKeys as FormKeys, FormType, Gender } from "../";
import type { ContactFormProps, FormProps } from "../";

export const ContactImage: React.FC<{ data: Person | Picture }> = ({ data }) => {
	switch (data?.__typename) {
		case "Person":
			return (
				<AgentWrapper>
					<Image
						unoptimized
						loading="lazy"
						src={imageOrFallback(
							data?.featuredImage?.url,
							`?q=75&w=1000&h=1000&fm=${AssetFormat.webp}&f=${AssetFocus.face}&fit=${AssetFit.thumb}`
						)}
						alt={data?.featuredImage?.description || data.name || ""}
						layout="fill"
						objectFit="cover"
					/>
				</AgentWrapper>
			);
		case "Picture":
			return (
				<AgentWrapper>
					<Image
						unoptimized
						loading="lazy"
						src={`${data?.file?.url}?q=75&w=1000&h=1000&fm=${AssetFormat.webp}&f=${AssetFocus.center}&fit=${AssetFit.fill}`}
						alt={data?.alt ?? ""}
						layout="fill"
						objectFit="cover"
					/>
				</AgentWrapper>
			);
		default:
			return null;
	}
};

export const ContactForm = ({
	contactDetails,
	additionalParameters = {},
	type,
	textCollection,
}: ContactFormProps) => {
	/**
	 * Static Text Content
	 */
	const { locale } = useRouter();
	const { t } = useTranslation(["forms", "property"]);

	/* @todo: refactor how label text is handled when there are more cases or contentful */
	const buttonLabel =
		type === FormType.EXPOSE_REQUEST ? t("property:request-expose") : t("forms:send");

	/* @todo: move headlines and subtitle into contentful contact form */
	const formTitle =
		type === FormType.SELECTED
			? t("forms:selected.headline")
			: type === FormType.PARTNER
			? t("forms:partner.headline")
			: type === FormType.CONTACT_NO_SNACKBAR
			? t("forms:contact-us.headline")
			: contactDetails?.__typename === "Person"
			? contactDetails.name
			: t("forms:formal.contact-us");
	const formSubtitle =
		type === FormType.SELECTED
			? t("forms:selected.subtitle")
			: type === FormType.PARTNER
			? t("forms:partner.subtitle")
			: type === FormType.CONTACT_NO_SNACKBAR
			? t("forms:contact-us.subtitle")
			: contactDetails?.__typename === "Picture"
			? t("forms:formal.contact-us-subtitle")
			: contactDetails.gender === Gender.male
			? t("forms:formal.your-contact.male")
			: t("forms:formal.your-contact.female");

	/**
	 * Form Initialization
	 */
	const { control, handleSubmit, errors } = useForm<FormProps>({
		defaultValues: {
			[FormKeys.firstName]: null,
			[FormKeys.lastName]: null,
			[FormKeys.email]: null,
			[FormKeys.phoneNumber]: null,
			[FormKeys.consent]: false,
			[FormKeys.newsletterConsent]: false,
		},
	});

	/**
	 * Form Submission Logic
	 */
	const { success, error, post, loading } = usePost(endpoints.lead.contactIntent.post());

	const { subscribe: subscribeToNewsletter } = useNewsletterSignup();

	const trackEvent = useTrackingEvent();

	const onSubmit: SubmitHandler<FormProps> = formData => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { consent, newsletterConsent, ...formPayload } = formData;
		const payload = {
			type,
			contact: { ...formPayload, language: locale.toUpperCase() },
			data: additionalParameters,
			source: getUTMParameters(),
		};

		if (newsletterConsent) {
			void subscribeToNewsletter(formPayload.email);
		}

		void post(payload).then(() => {
			// CONTACT_NO_SNACKBAR is used on demo pages and has no tracking needs
			if (type !== FormType.CONTACT_NO_SNACKBAR) {
				trackEvent({
					event: "formSubmission",
					eventType: type,
					// https://mece.atlassian.net/browse/EDP-3807
					formData: {
						email: formPayload.email ?? null,
						phone: formPayload.phone ?? null,
					},
				});
			}
		});
	};

	const postSuccessMessage = textCollection.items.find(
		({ id }) => id === "postSuccess"
	).description;
	const postErrorMessage = textCollection.items.find(({ id }) => id === "postError").description;

	/**
	 * Snackbar Intersection Logic
	 */
	const ref = React.useRef<HTMLDivElement>();
	const intersection = useIntersection(ref, {
		root: null,
		rootMargin: "0px",
		threshold: 0,
	});
	const intersecting = React.useMemo(() => intersection?.isIntersecting, [intersection]);
	const { setIntersecting } = useFormIntersectionContext();
	React.useEffect(() => {
		setIntersecting(intersecting);
	}, [setIntersecting, intersecting]);

	return (
		<Grid overflow>
			<RelativeRow>
				<Column>
					<Row>
						<Column l={5}>
							<Hidden s m>
								<RelativeStage>
									<ContactImage data={contactDetails} />
								</RelativeStage>
							</Hidden>
							<Hidden l>
								<ContactImage data={contactDetails} />
							</Hidden>
						</Column>
					</Row>
				</Column>
				<Column>
					<FormRow ref={ref} justify={Alignment.end}>
						<Column l={7}>
							<ContactFormBox ref={ref} id="contact-form">
								{success ? (
									<div data-test-id="contact-form:success">
										<Md source={postSuccessMessage} />
									</div>
								) : error ? (
									<div data-test-id="contact-form:error">
										<Md source={postErrorMessage} />
									</div>
								) : (
									<>
										<Typography
											tight
											component="h2"
											variant={TypographyVariant.headlineSerifLG}
										>
											{formTitle}
										</Typography>
										<Typography bottom light>
											{formSubtitle}
										</Typography>
										<form
											noValidate
											data-test-id="contact-form"
											onSubmit={handleSubmit(onSubmit)}
										>
											<Row>
												<Column l={COLUMN.TWO}>
													<Controller
														name={FormKeys.firstName}
														control={control}
														rules={{
															required: true,
														}}
														render={({ onChange, value }) => (
															<TextField
																required
																fullWidth
																data-test-id="contact-form:first-name"
																label={t(
																	"forms:formal.first-name.label"
																)}
																name={FormKeys.firstName}
																error={Boolean(
																	errors[FormKeys.firstName]
																)}
																errorText={t(
																	"forms:formal.first-name.error"
																)}
																helperText={
																	!errors[FormKeys.firstName] &&
																	" "
																}
																onChange={event => {
																	onChange(event.target.value);
																}}
																value={value}
															/>
														)}
													/>
												</Column>
												<Column l={COLUMN.TWO}>
													<Controller
														name={FormKeys.lastName}
														control={control}
														rules={{
															required: true,
														}}
														render={({ onChange, value }) => (
															<TextField
																required
																fullWidth
																data-test-id="contact-form:last-name"
																label={t(
																	"forms:formal.last-name.label"
																)}
																name={FormKeys.lastName}
																error={Boolean(
																	errors[FormKeys.lastName]
																)}
																errorText={t(
																	"forms:formal.last-name.error"
																)}
																helperText={
																	!errors[FormKeys.lastName] &&
																	" "
																}
																onChange={event => {
																	onChange(event.target.value);
																}}
																value={value}
															/>
														)}
													/>
												</Column>
												<Spacer spacing="xxs" />
												<Column l={COLUMN.TWO}>
													<Controller
														name={FormKeys.email}
														control={control}
														rules={{
															required: true,
															pattern: VALIDATION_PATTERNS.email,
														}}
														render={({ onChange, value }) => (
															<TextField
																required
																fullWidth
																data-test-id="contact-form:email"
																label={t(
																	"forms:formal.email.label"
																)}
																name={FormKeys.email}
																error={Boolean(
																	errors[FormKeys.email]
																)}
																errorText={t(
																	"forms:formal.email.error"
																)}
																helperText={
																	!errors[FormKeys.email] && " "
																}
																onChange={event => {
																	onChange(event.target.value);
																}}
																value={value}
															/>
														)}
													/>
												</Column>
												<Column l={COLUMN.TWO}>
													<Controller
														name={FormKeys.phoneNumber}
														control={control}
														rules={{
															pattern: VALIDATION_PATTERNS.tel,
														}}
														render={({ onChange, value }) => (
															<TextField
																fullWidth
																data-test-id="contact-form:phone"
																label={t(
																	"forms:formal.phone.label"
																)}
																name={FormKeys.phoneNumber}
																error={Boolean(
																	errors[FormKeys.phoneNumber]
																)}
																errorText={t(
																	"forms:formal.phone.error"
																)}
																helperText={
																	!errors[FormKeys.phoneNumber] &&
																	" "
																}
																onChange={event => {
																	onChange(event.target.value);
																}}
																value={value}
															/>
														)}
													/>
												</Column>
											</Row>
											<Spacer spacing="xxs" />
											<Controller
												name={FormKeys.consent}
												control={control}
												rules={{
													required: true,
												}}
												render={({ onChange, value }) => (
													<CheckBox
														checked={value}
														data-test-id="contact-form:consent"
														error={Boolean(errors[FormKeys.consent])}
														errorText={t("forms:formal.consent.error")}
														name={FormKeys.consent}
														label={
															<Transdown i18nKey="forms:formal.consent.label" />
														}
														labelSize="SM"
														onChange={event => {
															onChange(event.target.checked);
														}}
													/>
												)}
											/>
											<Spacer spacing="xxxs" />
											<Controller
												name={FormKeys.consent}
												control={control}
												render={({ onChange, value }) => (
													<CheckBox
														checked={value}
														data-test-id="contact-form:newsletter-consent"
														name={FormKeys.consent}
														label={t(
															"forms:newsletter.consent.label-simple"
														)}
														labelSize="SM"
														onChange={event => {
															onChange(event.target.checked);
														}}
													/>
												)}
											/>
											<Spacer spacing="s" />
											<Button
												fullWidth
												disabled={loading}
												loading={loading}
												type="submit"
												data-test-id="contact-form:submit"
												size="XL"
											>
												{buttonLabel}
											</Button>
										</form>
									</>
								)}
							</ContactFormBox>
						</Column>
						<Column s={0} l={1} />
					</FormRow>
				</Column>
			</RelativeRow>
		</Grid>
	);
};
