import React, { useState } from "react";

import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

import { HighlightInputMatch } from "@/components/highlight-input-match";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";
import { useTrackFeature } from "@/hooks/tag-manager";
import type { Prediction } from "@/pages/api/google-places-autocomplete/types";
import { getGeocodeByAddress } from "@/services/google-maps";
import type { PlaceData } from "@/services/google-maps";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";

import { AddressAutocomplete } from "../..";
import SkyScraperIcon from "../../../../../public/assets/svg-icons/i-building-skyscraper-modern.svg";
import Globe from "../../../../../public/assets/svg-icons/i-globe.svg";
import LocationIcon from "../../../../../public/assets/svg-icons/i-location.svg";
import type { OptionComponentProps } from "../../types";

import { ResultWrapper, LocationType, IconWrapper } from "./styled";
import type { LocationIconProps } from "./types";

const SuggestionIcon = ({ type }: LocationIconProps) => {
	switch (type) {
		case "CITY":
			return <SkyScraperIcon height="18" width="18" role="presentation" />;
		case "RURAL_DISTRICT":
			return <Globe height="18" width="18" role="presentation" />;
		case "STATE":
		// @ToDo - @design
		case "PROVINCE":
		// @ToDo - @design
		default:
			// @ToDo - @design - CITY_DISTRICT, ADDRESS, STREET use the default icon
			return <LocationIcon height="18" width="18" role="presentation" />;
	}
};

const OptionComponent = ({ query, suggestion }: OptionComponentProps) => {
	const { t } = useTranslation("buy");
	return (
		<ResultWrapper>
			<IconWrapper>
				<SuggestionIcon type={suggestion.location.type} />
			</IconWrapper>
			<div>
				<HighlightInputMatch value={suggestion.description} keyword={query} />
				<LocationType tight weight={FONT_WEIGHT.light} variant={TypographyVariant.bodySM}>
					{t(`buy:search.place-category_${suggestion.location.type}`)}
				</LocationType>
			</div>
		</ResultWrapper>
	);
};

export const MapLocationAutocomplete = () => {
	const { locale, push } = useRouter();
	const { t } = useTranslation(["common", "buy"]);
	const trackFeature = useTrackFeature();

	const [errorText, setErrorText] = useState(null);

	const navigateToMap = async (suggestion: Prediction) => {
		setErrorText(null);

		try {
			const { description, location } = suggestion;

			const placeData: PlaceData = await getGeocodeByAddress(description);

			if (!placeData) {
				// If there is no result from the Google Geocode, will still
				// redirect to the map page but it will get country default lat-lng
				console.warn(`No geocode response for ${description}`);
			}

			trackFeature("buyer_entry_autocomplete", description);

			void push({
				pathname: `/${dirs[Dirs.search].dir[locale]}/`,
				href: `/${dirs[Dirs.search].dir[locale]}/`,
				query: {
					...(placeData && {
						lat: placeData.location_latitude.toFixed(5),
						lng: placeData.location_longitude.toFixed(5),
					}),
					zoom: location.zoom,
				},
			});
		} catch (error: unknown) {
			console.error(error);
			setErrorText(t("common:generic-error"));
		}
	};

	return (
		<AddressAutocomplete
			errorText={errorText}
			placeholderText={t("buy:search.autocomplete.placeholder")}
			handleButtonClick={navigateToMap}
			handleOptionClick={navigateToMap}
			OptionComponent={OptionComponent}
		/>
	);
};
