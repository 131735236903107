import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";
import { TYPOGRAPHY, FONT_WEIGHT } from "@/theme";

import type { CheckboxProps } from "./types";

export const StyledCheckboxInput = styled.input<
	PropsWithTheme<HTMLInputElement> & Pick<CheckboxProps, "checked">
>`
	&[type="checkbox"] {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0, 0, 0, 0);
		white-space: nowrap;
		border: 0;
	}
`;

export const StyledLabel = styled.label<
	PropsWithTheme<HTMLLabelElement> & Pick<CheckboxProps, "checked" | "labelSize">
>`
	display: flex;
	gap: 8px;
	align-items: flex-start;
	cursor: pointer;
	user-select: none;
	font-weight: ${FONT_WEIGHT.light};
	${({ labelSize }) => css`
		${TYPOGRAPHY.body[labelSize]};
	`};
`;

export const StyledText = styled.span<Pick<CheckboxProps, "labelSize">>`
	flex: 1;
	position: relative;

	// Sometimes we parse markdown for the label and want to avoid top margins from those components
	> :first-child {
		margin: 0;
	}

	${({ labelSize }) => css`
		// Adjust the vertical alignment of the text to the checkbox
		top: ${labelSize === "LG" ? "-4px" : "-1px"};

		// Ensures consistent font sizing with children i.e. from markdown
		> * {
			${TYPOGRAPHY.body[labelSize]};
		}
	`};
`;
