import styled from "@emotion/styled";

import Loading from "../../../public/assets/svg-icons/loading.svg";

export const LoadingSpinner = styled(Loading)`
	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	animation: rotate 1s linear infinite;
`;
