import { css } from "@emotion/react";
import styled from "@emotion/styled";

import type { PropsWithTheme } from "@/theme";
import {
	ButtonVariantStyles,
	FONT_WEIGHT,
	TYPOGRAPHY,
	TextButtonVariantStyles,
	TextFieldSizes,
} from "@/theme";

import ChevonDoubleVertical from "../../../public/assets/svg-icons/i-chevron-double-vertical.svg";

import type { StyledSelectProps } from "./types";

export const WrappingLabel = styled.label<PropsWithTheme & StyledSelectProps>`
	${TYPOGRAPHY.buttonLabels.MD};
	align-items: center;
	cursor: pointer;
	display: inline-flex;
	font-weight: ${FONT_WEIGHT.medium};
	gap: 4px;
	justify-content: space-between;
	position: relative;
	width: 100%;
	${({ error, fullWidth, size = "XL", variant, theme }) => css`
		${TextFieldSizes[size]};
		${ButtonVariantStyles?.[variant] ?? TextButtonVariantStyles?.[variant]}
		width: ${fullWidth ? "100%" : "max-content"};

		${error && `box-shadow: ${theme.tokens.border.insetRegular} ${theme.palette.red[500]};`}

		&:hover {
			${ButtonVariantStyles?.[variant]?.hover ?? TextButtonVariantStyles?.[variant]?.hover}
		}

		&:active {
			${ButtonVariantStyles?.[variant]?.active ?? TextButtonVariantStyles?.[variant]?.active}
		}
	`};
`;

export const WrappingDiv = WrappingLabel.withComponent("div");

export const StyledSelect = styled.select`
	appearance: none;
	opacity: 0;
	background: transparent;
	border: 0;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

export const StyledChevron = styled(ChevonDoubleVertical)`
	pointer-events: none;
`;
