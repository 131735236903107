import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SplideSlide, SplideTrack } from "@splidejs/react-splide";

import type { PropsWithTheme } from "@/theme";

export const StyledTrack = styled(SplideTrack)`
	padding-bottom: 10px;
`;

export const StyledThumb = styled(SplideSlide)<
	PropsWithTheme & {
		selected?: boolean;
	}
>`
	width: 100px;
	height: 70px;
	border: 4px solid transparent;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	${({ theme: { palette }, selected }) => css`
		border-color: ${selected ? palette.blue[500] : "initial"};

		&:focus-within {
			border-color: ${palette.blue[500]};
		}
	`};
`;
