import React from "react";

import { CacheProvider } from "@emotion/react";
import { appWithTranslation } from "next-i18next";
import smoothscroll from "smoothscroll-polyfill";

import type { AppProps as NextAppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

import { Favicons } from "@/components/favicons";
import { PREVIEW } from "@/config";
import { JobsProvider } from "@/context/jobs";
import { ValuationProvider } from "@/context/valuation";
import { cache } from "@/emotion";
import { useTagManager, useTrackPage } from "@/hooks/tag-manager";

import pkg from "../../package.json";

import "./styles.css";

type AppProps = NextAppProps & { err: Error };

const App = ({ Component, pageProps, err }: AppProps) => {
	const { asPath } = useRouter();

	useTagManager();
	useTrackPage(asPath);

	React.useEffect(() => {
		smoothscroll.polyfill();
	}, []);

	return (
		<CacheProvider value={cache}>
			<Head>
				<meta charSet="utf-8" />
				<meta
					name="viewport"
					content="initial-scale=1.0, width=device-width, viewport-fit=cover"
				/>
				<meta name="build-time" content={new Date().toString()} />
				<meta name="version" content={pkg.version} />

				{/* We want all pages on preview.evernest.com to be ignored */}
				{PREVIEW && <meta name="robots" content="noindex, nofollow" />}
			</Head>
			<Script id="gtm" src="/static/gtm.js" strategy="afterInteractive" />
			<Favicons />
			<ValuationProvider>
				<JobsProvider>
					{/* Workaround for https://github.com/vercel/next.js/issues/8592 */}
					<Component {...pageProps} err={err} />
				</JobsProvider>
			</ValuationProvider>
		</CacheProvider>
	);
};

export default appWithTranslation(App as React.FC);
