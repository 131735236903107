import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "next-i18next";
import type { DropzoneInputProps, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";

import { useRouter } from "next/router";

import { CheckBox } from "@/components/checkbox";
import { COLUMN, COLCOUNT, Column, Hidden, Row } from "@/components/grid";
import { Transdown } from "@/components/i18n";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import { Select } from "@/components/select";
import { TextField } from "@/components/text-fields";
import { Typography } from "@/components/typography/typography";
import {
	DROPZONE_CONFIG,
	FIXED_FIELDS_FILES,
	FIXED_FIELDS_TEXT,
	MAX_FILE_SIZE,
} from "@/constants/greenhouse";
import { StyledArrowRightIcon } from "@/design-system/atoms/button";
import { endpoints } from "@/endpoints";
import { useJob } from "@/hooks/jobs";
import { useNewsletterSignup } from "@/hooks/newsletter";
import { useTrackingEvent } from "@/hooks/tag-manager";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";

import type { GreenhouseFormProps, GreenhouseApplicationFormProps } from "../";
import { GreenhouseFormKeys as FormKeys, StyledErrorText } from "../";
import {
	Dropped,
	Droppedzone,
	Dropzone,
	DropzoneLabel,
	Ellipsis,
	FlexButton,
	RoundButton,
	StyledCircularProgress,
	StyledFileLabel,
} from "../";
import DeleteIcon from "../../../../public/assets/svg-icons/i-cross-lg.svg";
import AddIcon from "../../../../public/assets/svg-icons/i-plus-lg.svg";

export const JobApplicationForm = ({
	forwardedRef,
	jobLocations,
	textCollection,
	jobId,
	isAgentApplicationForm,
	resumeRequired,
}: GreenhouseApplicationFormProps) => {
	const {
		control,
		register,
		handleSubmit: handleFormSubmit,
		errors,
		watch,
	} = useForm<GreenhouseFormProps>({
		defaultValues: {
			[FormKeys.firstName]: null,
			[FormKeys.lastName]: null,
			[FormKeys.location]: "",
			[FormKeys.email]: null,
			[FormKeys.resume]: undefined,
			[FormKeys.phone]: undefined,
			[FormKeys.consent]: false,
			[FormKeys.newsletterConsent]: false,
			...(!isAgentApplicationForm && { [FormKeys.coverLetter]: undefined }),
		},
	});
	const { query } = useRouter();

	const { t } = useTranslation(["common", "forms"]);

	const [selectedJobId, setSelectedJobId] = useState<string>();

	const { gtmEventType, jobQuestions } = useJob(selectedJobId);

	const trackEvent = useTrackingEvent();
	const [resumeFiles, setResumeFiles] = useState<FileWithPath[]>([]);
	const [coverLetterFiles, setCoverLetterFiles] = useState<FileWithPath[]>([]);

	const [greenhouseSuccess, setGreenhouseSuccess] = useState(false);
	const [greenhouseError, setGreenhouseError] = useState(false);
	const [greenhouseLoading, setGreenhouseLoading] = useState(false);

	const { subscribe } = useNewsletterSignup();

	const handleSubmit = useCallback(
		async formPayload => {
			try {
				setGreenhouseLoading(true);
				if (formPayload.newsletterConsent) {
					void subscribe(formPayload.email);
				}

				const formData = new FormData();

				formData.append(FormKeys.firstName, formPayload[FormKeys.firstName]);
				formData.append(FormKeys.lastName, formPayload[FormKeys.lastName]);
				formData.append(FormKeys.email, formPayload[FormKeys.email]);
				formData.append(FormKeys.phone, formPayload[FormKeys.phone]);
				formData.append(
					"data_compliance[gdpr_consent_given]",
					formPayload[FormKeys.consent]
				);

				// Append any dynamic questions
				jobQuestions.forEach(question => {
					const name = question.fields[0].name;
					formData.append(name, formPayload[name]);
				});

				// gh_src is appended by and to Greeenhouse Tracking Links
				// It is used for tracking inside Greenhouse for our recruitment team
				if (query["gh_src"]) {
					formData.append("mapped_url_token", query["gh_src"] as string);
				}

				if (resumeFiles.length > 0) {
					formData.append(FormKeys.resume, resumeFiles[0]);
				}

				if (coverLetterFiles.length > 0) {
					formData.append(FormKeys.coverLetter, coverLetterFiles[0]);
				}

				const result = await fetch(endpoints.api.greenhouse.baseUrl(), {
					method: "POST",
					headers: {
						"x-greenhouse-jobid": selectedJobId,
					},
					body: formData,
				});

				if (result.status !== 200) {
					throw new Error(`with status ${result.status}`);
				}

				setGreenhouseSuccess(true);

				trackEvent({
					event: "formSubmission",
					eventType: gtmEventType,
					// https://mece.atlassian.net/browse/EDP-3807
					formData: {
						email: formPayload.email ?? null,
						phone: formPayload.phone ?? null,
					},
				});
			} catch (error: unknown) {
				console.error("Error: Greenhouse job application", error);
				setGreenhouseError(true);
			} finally {
				setGreenhouseLoading(false);
			}
		},
		[
			resumeFiles,
			subscribe,
			trackEvent,
			jobQuestions,
			coverLetterFiles,
			selectedJobId,
			gtmEventType,
			query,
		]
	);

	const onDropCoverLetter = acceptedFiles => {
		setCoverLetterFiles(s => [...s, ...acceptedFiles]);
	};

	const onDropResume = acceptedFiles => {
		setResumeFiles(s => [...s, ...acceptedFiles]);
	};

	const removeCoverLetterFile = file => () => {
		setCoverLetterFiles(s => {
			const files = [...s];
			files.splice(files.indexOf(file), 1);
			return files;
		});
	};

	const removeResumeFile = file => () => {
		setResumeFiles(s => {
			const files = [...s];
			files.splice(files.indexOf(file), 1);
			return files;
		});
	};

	const {
		getRootProps: getRootPropsCoverLetter,
		getInputProps: getInputPropsCoverLetter,
		open: openCoverLetter,
		fileRejections: fileRejectionsCoverLetter,
	} = useDropzone({
		onDrop: onDropCoverLetter,
		...DROPZONE_CONFIG,
	});
	const { ref: inputRefCoverLetter, ...inputPropsCoverLetter } = getInputPropsCoverLetter({
		name: FormKeys.coverLetter,
	}) as DropzoneInputProps & { ref: React.MutableRefObject<HTMLInputElement> };

	const {
		getRootProps: getRootPropsResume,
		getInputProps: getInputPropsResume,
		open: openResume,
		fileRejections: fileRejectionsResume,
	} = useDropzone({
		onDrop: onDropResume,
		...DROPZONE_CONFIG,
	});
	const { ref: inputRefResume, ...inputPropsResume } = getInputPropsResume({
		name: FormKeys.resume,
	}) as DropzoneInputProps & { ref: React.MutableRefObject<HTMLInputElement> };

	const postCareerErrorMessage = textCollection.items.find(
		({ id }) => id === "postCareerError"
	).description;
	const postCareerSuccessMessage = textCollection.items.find(
		({ id }) => id === "postCareerSuccess"
	).description;

	const watchJobLocation = watch(FormKeys.location);

	useEffect(() => {
		if (jobId) {
			setSelectedJobId(jobId.toString());
			return;
		}

		// If there is only 1 attached jobLocation, use it directly
		if (jobLocations?.length === 1) {
			setSelectedJobId(jobLocations[0].jobId);
			return;
		}

		// Else get from passed in job locations from Contentful
		const id =
			jobLocations?.find(({ jobLocation }) => jobLocation === watchJobLocation)?.jobId ||
			jobLocations?.[0].jobId;
		setSelectedJobId(id);
	}, [jobId, jobLocations, watchJobLocation]);

	return (
		<div ref={forwardedRef} data-test-id="job-application:form" id="contact-form">
			{greenhouseSuccess ? (
				<div data-test-id="job-application:success">
					<Md source={postCareerSuccessMessage} />
				</div>
			) : greenhouseError ? (
				<div data-test-id="job-application:error">
					<Md source={postCareerErrorMessage} />
				</div>
			) : (
				<>
					<Typography
						tight
						component="h3"
						id="apply-dialog-aria-label"
						variant={TypographyVariant.headlineSerifLG}
					>
						{t("forms:apply-now:title")}
					</Typography>
					<Typography bottom light tight weight={FONT_WEIGHT.light}>
						{t("forms:in-two-minutes")}
					</Typography>
					<Spacer spacing="xxs" />
					<form
						noValidate
						data-test-id="job-application:form"
						onSubmit={handleFormSubmit(handleSubmit)}
					>
						<Row>
							{jobLocations?.length > 1 && (
								<Column>
									<Controller
										name={FormKeys.location}
										control={control}
										rules={{
											required: true,
										}}
										render={({ onChange, value }) => (
											<Select
												required
												data-test-id="job-application:location"
												fullWidth
												label={t("forms:location.label")}
												name={FormKeys.location}
												placeholderText="-"
												size="LG"
												value={value}
												error={Boolean(errors[FormKeys.location])}
												errorText={t("forms:location.error")}
												options={jobLocations.map(({ jobLocation }) => ({
													name: jobLocation,
													value: jobLocation,
												}))}
												onSelect={value => {
													onChange(value);
												}}
											/>
										)}
									/>
									<Spacer spacing="xxs" />
								</Column>
							)}
							<>
								{FIXED_FIELDS_TEXT.map(fieldName => {
									return (
										<Column key={`col-${fieldName}`} l={COLUMN.TWO}>
											<Controller
												name={fieldName}
												control={control}
												rules={{
													required: true,
												}}
												render={({ onChange, value }) => (
													<TextField
														required
														fullWidth
														data-test-id={`job-application:${fieldName}`}
														label={t(`forms:${fieldName}.label`)}
														name={fieldName}
														error={Boolean(errors[fieldName])}
														errorText={t(`forms:${fieldName}.error`)}
														helperText={!errors[fieldName] && " "}
														onChange={event =>
															onChange(event.target.value)
														}
														textFieldSize="LG"
														value={value}
													/>
												)}
											/>
											<Spacer spacing="xxs" />
										</Column>
									);
								})}
							</>
							<>
								{jobQuestions.map((question, index) => {
									const name = question.fields?.[0]?.name;
									const isRequired = question.required;
									const type = question.fields?.[0]?.type;
									const options = question.fields?.[0]?.values;
									const addSpacer = index < jobQuestions.length - 1;

									// These case values are coming from Greenhouse
									switch (type) {
										case "multi_value_single_select":
											return (
												<Column>
													<Controller
														name={name}
														control={control}
														rules={{
															required: isRequired,
														}}
														render={({ onChange, value }) => (
															<Select
																fullWidth
																data-test-id="valuation-property-intent:intent"
																error={Boolean(errors[name])}
																errorText={t(
																	"forms:required-field"
																)}
																label={question.label}
																name={name}
																placeholderText="-"
																required={isRequired}
																size="LG"
																value={value}
																options={options.map(
																	({ label, value }) => ({
																		name: t(
																			`forms:greenhouse-option.${label}`,
																			label
																		),
																		value: value.toString(),
																	})
																)}
																onSelect={value => {
																	onChange(value);
																}}
															/>
														)}
													/>
													{addSpacer && <Spacer spacing="xxs" />}
												</Column>
											);

										case "textarea":
										case "input_text":
											return (
												<Column>
													<Controller
														name={name}
														control={control}
														rules={{
															required: isRequired,
														}}
														render={({ onChange, value }) => (
															<TextField
																required={isRequired}
																fullWidth
																data-test-id={`job-application:${name}`}
																label={question.label}
																name={name}
																error={Boolean(errors[name])}
																errorText={t(
																	"forms:required-field"
																)}
																helperText={!errors[name] && " "}
																onChange={event =>
																	onChange(event.target.value)
																}
																textFieldSize="LG"
																value={value}
															/>
														)}
													/>
													{addSpacer && <Spacer spacing="xxs" />}
												</Column>
											);
									}
								})}
							</>
							<Spacer spacing="xxxs" />
							<>
								{FIXED_FIELDS_FILES.map(fieldName => {
									const isCoverLetter = fieldName === FormKeys.coverLetter;

									// No cover letter on agent application form
									if (isCoverLetter && isAgentApplicationForm) {
										return;
									}
									const isRequired = !isCoverLetter && resumeRequired;

									const files = isCoverLetter ? coverLetterFiles : resumeFiles;
									const removeFile = isCoverLetter
										? removeCoverLetterFile
										: removeResumeFile;
									const getRootProps = isCoverLetter
										? getRootPropsCoverLetter
										: getRootPropsResume;
									const inputProps = isCoverLetter
										? inputPropsCoverLetter
										: inputPropsResume;
									const inputRef = isCoverLetter
										? inputRefCoverLetter
										: inputRefResume;
									const openFileSelect = isCoverLetter
										? openCoverLetter
										: openResume;
									const fileRejections = isCoverLetter
										? fileRejectionsCoverLetter
										: fileRejectionsResume;

									const label = isRequired
										? `${t(`forms:add-${fieldName}.label`)}*`
										: t(`forms:add-${fieldName}.label-optional`);

									return (
										<Column
											key={`col-${fieldName}`}
											s={isAgentApplicationForm && 0}
											l={`var(${COLCOUNT})`}
										>
											{isCoverLetter && <Spacer spacing="xxs" />}
											{files.length > 0 ? (
												<>
													<Typography
														bottom
														tight
														weight={FONT_WEIGHT.light}
													>
														{t(`forms:${fieldName}.label`)}
													</Typography>
													<Droppedzone>
														{files.map(file => (
															<Dropped key={file.path}>
																<DropzoneLabel>
																	<Ellipsis tight>
																		{file.path}
																	</Ellipsis>
																</DropzoneLabel>
																<RoundButton
																	type="button"
																	onClick={removeFile(file)}
																>
																	<DeleteIcon
																		width="24"
																		height="24"
																	/>
																</RoundButton>
															</Dropped>
														))}
													</Droppedzone>
												</>
											) : (
												<Dropzone {...getRootProps()}>
													<input
														{...inputProps}
														name={fieldName}
														ref={element => {
															register(element, {
																required: isRequired,
																validate: value => {
																	const file =
																		value?.length > 0 &&
																		value[0];
																	return (
																		!file ||
																		file?.size <= MAX_FILE_SIZE
																	);
																},
															});
															inputRef.current = element;
														}}
													/>
													<DropzoneLabel>
														<StyledFileLabel
															error={Boolean(errors[fieldName])}
															tight
														>
															{label}
														</StyledFileLabel>
													</DropzoneLabel>
													<RoundButton
														type="button"
														onClick={openFileSelect}
													>
														<AddIcon width="24" height="24" />
													</RoundButton>
												</Dropzone>
											)}

											{fileRejections.length > 0 && (
												<StyledErrorText
													data-test-id={`job-application:error-${fieldName}`}
													role="alert"
												>
													{t(
														`forms:file.${fileRejections[0].errors[0].code}`,
														{
															maxSize: MAX_FILE_SIZE / 1000000,
														}
													)}
												</StyledErrorText>
											)}
											{errors[fieldName] && (
												<StyledErrorText role="alert">
													{t("forms:required-field")}
												</StyledErrorText>
											)}
										</Column>
									);
								})}
							</>
							<Spacer spacing="xxs" />
						</Row>

						<Controller
							name={FormKeys.consent}
							control={control}
							rules={{
								required: true,
							}}
							render={({ onChange, value }) => (
								<CheckBox
									required
									checked={value}
									data-test-id="job-application:consent"
									error={Boolean(errors[FormKeys.consent])}
									errorText={t("forms:consent.error")}
									name={FormKeys.consent}
									label={<Transdown i18nKey="forms:consent.label" />}
									labelSize="SM"
									onChange={event => {
										onChange(event.target.checked);
									}}
								/>
							)}
						/>
						<Spacer spacing="xxxs" />
						<Hidden l>
							<Controller
								name={FormKeys.newsletterConsent}
								control={control}
								render={({ onChange, value }) => (
									<CheckBox
										checked={value}
										data-test-id="job-application:newsletter-consent"
										name={FormKeys.newsletterConsent}
										label={t("forms:newsletter.consent.label-simple")}
										labelSize="SM"
										onChange={event => {
											onChange(event.target.checked);
										}}
									/>
								)}
							/>
							<Spacer spacing="s" />
						</Hidden>
						<FlexButton
							type="submit"
							data-test-id="job-application:submit"
							disabled={greenhouseLoading}
						>
							{greenhouseLoading && <StyledCircularProgress />}
							{t("forms:apply-now:button-label")}
							<StyledArrowRightIcon />
						</FlexButton>
					</form>
				</>
			)}
		</div>
	);
};
